<template>
  <v-menu
    v-model="menu"
    transition="scale-transition"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateFormat"
        :label="label ? 'Periodo' : ''"
        :clearable="clearable"
        hide-details
        outlined
        readonly
        filled
        dense
      >
        <template v-slot:append-outer>
          <v-icon
            v-bind="attrs"
            v-on="on"
            tabindex="1"
            :color="disabled ? '' : 'info'"
            :title="disabled ? '' : 'Seleccionar periodo'"
            :disabled="disabled"
          >
            fas fa-calendar-day
          </v-icon>
        </template>
      </v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      type="month"
      color="primary"
      :min="fecha_minima"
      :max="fecha_maxima"
      show-current
      scrollable
      no-title
      @input="eventoSeleccion"
    ></v-date-picker>
  </v-menu>
</template>

<script>
/**
 *  NOTAS : Este componente viene a reemplazar a FechaPickerMes
 * 
 *   - Este devuelve la fecha en formato MM/YYYY, en cambio FechaPickerMes devuelve la fecha en formato 01/MM/YYYY
 *  
 *   - Ademas resuelve el bug que tenia FechaPickerMes cuando se limpiaba con la X del clearable que no 'borraba' el
 *     valor del v-date-picker y quedaba 'seleccionado' visualmente
 * 
 */
export default {
  data () {
    return {
      date: null,
      menu: false
    }
  },
  props: {
    value: String,
    label: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    fecha_minima: { // fecha mínima seleccionable en formato aaaa-mm
      type: String,
      default: null
    },
    fecha_maxima: { // fecha máxima seleccionable en formato aaaa-mm
      type: String,
      default: null
    }
  },
  computed: {
    dateFormat: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  watch: {
    date () {
      this.dateFormat = this.formatDate(this.date)
      this.$emit('change')
    },
    dateFormat (newVal, oldVal) {
      if (!newVal && oldVal) {
        this.date = null
      }
    }
  },
  methods: {
    eventoSeleccion (periodo) {
      // devuelve el periodo cuando hace click en un mes del modal
      this.$emit('selecciono', this.formatDate(periodo))
    },
    formatDate (date) {
      if (!date) return null
      const [year, month] = date.split('-')
      return `${month}/${year}`
    }
  }
}
</script>