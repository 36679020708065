<template>
  <div>
    <v-row class="d-flex justify-center mt-0">
      <v-col cols="12">
        <v-expansion-panels v-model="panel">
          <v-expansion-panel readonly>
            <v-expansion-panel-content class="pt-4">
              <v-form @submit.prevent="buscar">
                <!-- Sucursal, Local y Periodo -->
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" md="2" sm="2" class="py-1">
                    <v-autocomplete
                      v-model="filtro.sucursal_codigo"
                      label="Sucursal"
                      item-text="nombre"
                      item-value="id"
                      :items="sucursales"
                      hide-details
                      outlined
                      dense
                      clearable
                      @change="buscar()"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="4" sm="6" class="py-1">
                    <v-autocomplete
                      v-model="filtro.local_codigo"
                      label="Local"
                      item-text="nombre"
                      item-value="id"
                      :items="locales"
                      hide-details
                      outlined
                      dense
                      clearable
                      @change="buscar()"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3" sm="3" class="py-1">
                    <PeriodoPicker
                      v-model="filtro.periodo"
                      :label="true"
                      :fecha_minima="moment(new Date()).subtract(12, 'months').format('YYYY-MM')"
                      @change="buscar()"
                    />
                  </v-col>
                  <v-col cols="12" md="3" sm="12" class="py-1">
                    <v-autocomplete
                      v-model="filtro.tipo_objetivo"
                      label="Tipo Obj."
                      item-text="tipo_obj_nombre"
                      item-value="tipo_obj_id"
                      :items="tiposObjetivos"
                      hide-details
                      outlined
                      dense
                      clearable
                      @change="buscar()"
                    ></v-autocomplete>
                  </v-col>
                  <!-- <v-col cols="12" md="2" sm="12" class="pt-0">
                    <BtnFiltro
                      :loading="load"
                      clase="mt-0 pt-0"
                      @action="buscar()"
                      @clear="limpiar()"
                    />
                  </v-col> -->
                </v-row>
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" align="right" class="pr-0">
                    <BtnFiltro
                      :loading="load"
                      clase="mt-0 pt-0"
                      @action="buscar()"
                      @clear="limpiar()"
                    />
                  </v-col>
                </v-row>
              </v-form>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <v-row 
          :no-gutters="$vuetify.breakpoint.xs" class="pt-2"
          v-for="(item, index) in graficos"
          :key="index"
        >
          <!-- Donas -->
          <v-col cols="12" md="6" class="py-2">
            <v-card
              elevation="2"
              outlined
              :loading="load"
            >
              <v-card-title class="pb-0">
                <v-icon left color="info">fas fa-chart-line</v-icon> Objetivo Periodo {{ filtro.periodo }}
              </v-card-title>
              <v-card-text class="pt-2">
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" class="pb-0">
                    <Doughbut 
                      :datos="item.dona"
                      :ref="item.dona.ref"
                    />
                  </v-col>
                  <v-col 
                    cols="3" sm="2" md="2" lg="2" xl="2" class="pa-0"
                    v-for="(elem, ind) in item.donas_hijas"
                    :key="ind"
                  >
                    <Doughbut 
                      :datos="elem"
                      :ref="elem.ref"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <!-- Barras -->
          <v-col cols="12" md="6" class="py-2">
            <v-card
              elevation="2"
              outlined
              :loading="load"
            >
              <v-card-title>
                <v-icon left color="info">fas fa-medal</v-icon> Ranking Vendedores
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <Barra 
                      :datos="item.barra"
                      :ref="item.barra.ref"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="cantVendedores"
                      label="Ranking"
                      item-text="nombre"
                      item-value="cantidad"
                      :items="listCantVend"
                      hide-details
                      outlined
                      dense
                      @change="generarGraficos()"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row :no-gutters="$vuetify.breakpoint.xs" v-if="proyecciones.length > 0">
          <!-- Linea Proyección Facturación -->
          <v-col cols="12" class="py-1">
            <v-card
              elevation="2"
              outlined
              class="pb-4"
            >
              <v-card-title>
                <v-toolbar elevation="0" flat style="word-break: normal;" :class="$vuetify.breakpoint.xs ? 'text-center' : ''"><v-icon left color="info">fas fa-mobile-alt</v-icon> Proyectado Facturación para {{ moment(new Date()).format('MMMM').toUpperCase() }} del {{ moment(new Date()).format('YYYY') }}</v-toolbar>
              </v-card-title>
              <v-card-text>
                <v-row :no-gutters="$vuetify.breakpoint.xs">
                  <v-col cols="12" class="py-1">
                    <Linea
                      :datos="lineaProy"
                      ref="line1"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment'
import PeriodoPicker from '../../components/util/PeriodoPicker.vue'
import BtnFiltro from '../../components/util/BtnFiltro.vue'
import { Chart } from 'chart.js'
import Doughbut from '../../components/charts/Doughbut.vue'
import { cadenaVacia, format_money_round, order_list_by, roundNumber, order_list_by_date2, objetoNoVacio } from '../../util/utils'
import Barra from '../../components/charts/Bar.vue'
import Linea from '../../components/charts/Line.vue'
import momentTz from 'moment-timezone'
export default {
  data() {
    return {
      format_money_round: format_money_round,
      cadenaVacia: cadenaVacia,
      moment: moment,
      rango: false,
      panel: 0,
      filtro: {
        sucursal_codigo: null,
        local_codigo: null,
        periodo: moment(new Date).format('MM/YYYY'),
        tipo_objetivo: null
      },
      sucursales: [],
      locales: [],
      localesTodos: [],
      load: false,
      objVendedores: [],
      objLocales: [],
      selectedElem2: {id: 2, nombre: '10 Puestos', valor: 10},
      cantElementos: [{id: 1, nombre: '5 Puestos', valor: 5}, {id: 2, nombre: '10 Puestos', valor: 10}, {id: 3, nombre: '20 Puestos', valor: 20}, {id: 4, nombre: 'Todos', valor: 1000}],
      porcEqAlcanzado: 0,
      porcEqFaltante: 0,
      permisoPais: 0,
      graficos: [],
      ventas: [],
      proyecciones: [],
      diasHabiles: 0,
      supervisor: 0,
      lineaProy: {
        id: 'line1',
        data: {
          datasets: [],
          labels: []
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          aspectRatio: 1.5, // controla el tamaño del gráfico
          title: {},
          legend: {
            display: false
          },
          scales: {
            yAxes: [{
              id: 'y-axis-1',
              ticks: {
                beginAtZero: true,
                min: 0,
                max: 0
              },
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              },
              position: 'left'
            },
            {
              id: 'y-axis-2',
              ticks: {
                beginAtZero: true,
                min: 0,
                max: 0
              },
              gridLines: {
                display: true,
                drawOnChartArea: false
              },
              scaleLabel: {
                display: false
              },
              position: 'right'
            }],
            xAxes: [{
              ticks: {
                beginAtZero: true,
                autoSkip: false,
                maxRotation: 90,
                minRotation: 90
              },
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: false
              }
            }]
          },
          tooltips: {
            mode: 'point',
            axis: 'xy',
            callbacks: {
              title: function(){
                return ''
              },
              label: function (tooltipItems, datasets) {
                return `${tooltipItems.label.replace('.', '')} $${roundNumber(tooltipItems.value, 0)}`
              }
            }
          }
        }
      },
      tiposObjetivos: [],
      cantVendedores: 10,
      listCantVend: [{id: 1, nombre: 'Primeros 5', cantidad: 5}, {id: 2, nombre: 'Primeros 10', cantidad: 10}, {id: 3, nombre: 'Todos', cantidad: 10000}]
    }
  },
  mounted () {
    // CONFIGURACION GLOBAL PARA LOS GRAFICOS, APLICA PARA TODOS
    // tamaño de la font
    Chart.defaults.global.defaultFontSize = 11
    // familia de la font
    Chart.defaults.global.defaultFontFamily = 'Arial'
    // estilo de la font
    Chart.defaults.global.defaultFontStyle = 'normal'
    // color de la font
    Chart.defaults.global.defaultFontColor = this.$vuetify.theme.dark ? 'white' : 'black'
    // posición de los valores del chart
    Chart.defaults.global.legend.position = 'bottom'
  },
  components: {
    Doughbut,
    Barra,
    PeriodoPicker,
    BtnFiltro,
    Linea
  },
  created () {
    this.initForm()
    moment.locale('es-es')
  },
  methods: {
    async initForm(){
      this.sucursales = JSON.parse(localStorage.getItem('sucursales'))
      this.localesTodos = JSON.parse(localStorage.getItem('locales'))
      this.$store.state.loading = true
      let initPeticion = await this.$store.dispatch('objetivos/init_control_objetivos')
      this.$store.state.loading = false
      if (initPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: initPeticion.msj,
          color: 'error',
        })
        return
      }
      this.permisoPais = initPeticion.permiso
      this.diasHabiles = initPeticion.dias_habiles
      this.supervisor = initPeticion.sup
      this.tiposObjetivos = initPeticion.tipos_objetivos
      // para iniciar el formulario asigno un elemento
      this.filtro.tipo_objetivo = this.tiposObjetivos[0].tipo_obj_id
      if (this.supervisor == 1 && this.permisoPais == 1) this.filtro.sucursal_codigo = this.sucursales[0].id
      this.buscar()
    },
    async buscar(){
      // 10-10-2024 por MMURILLO, siempre tienen que elegir el tipo de objetivo a buscar
      if (cadenaVacia(this.filtro.tipo_objetivo)) return
      if (cadenaVacia(this.filtro.sucursal_codigo) && this.permisoPais == 0){
        this.filtro.local_codigo = null
        this.locales = []
        return
      }
      if (!cadenaVacia(this.filtro.sucursal_codigo)){
        let distintaSucursal = this.locales.filter(element => element.sucursal == this.filtro.sucursal_codigo)
        if (distintaSucursal.length == 0){
          this.filtro.local_codigo = null
          this.locales = this.localesTodos.filter(element => element.sucursal == this.filtro.sucursal_codigo)
        }
      }else this.locales = []
      this.objVendedores = []
      this.objLocales = []
      this.ventas = []
      this.proyecciones = []
      this.load = true
      let objPeticion = await this.$store.dispatch('objetivos/get_objetivos_alcanzados', this.filtro)
      this.load = false
      if (objPeticion.resultado == 0){
        this.$store.dispatch('show_snackbar', {
          text: objPeticion.msj,
          color: 'error',
        })
        return
      }
      this.objVendedores = objPeticion.obj_vendedores
      this.objLocales = objPeticion.obj_locales
      this.ventas = objPeticion.ventas
      // calculo el porcentaje de objetivo cumplido por cada vendedor
      this.generarGraficos()
      this.generarProyeccion()
    },
    limpiar(){
      this.filtro = {
        sucursal: null,
        local: null,
        periodo: null
      }
    },
    executeDoughnutChart(chartData){
      if (chartData.opcion == 1){
        // armo una sola dona
        let dona = this.setNewDona(`${chartData.pais == 1 ? `pais${chartData.indice}` : (chartData.sucursal == 1 && chartData.local == 0) ? `sucursal${chartData.indice}` : `local${chartData.indice}`}`)
        dona.data.datasets = [{
          data: chartData.faltante == 0 ? [chartData.alcanzado] : [chartData.alcanzado, chartData.faltante],
          backgroundColor: chartData.faltante == 0 ? ['#00FF00'] : ['#00FF00', '#999999'],
          borderColor: chartData.faltante == 0 ? ['#000000'] : ['#000000', '#000000']
        }]
        dona.data.labels = [`${chartData.pais == 1 ? `PAIS` : (chartData.sucursal == 1 && chartData.local == 0) ? `${this.sucursales.filter(element => element.id == this.filtro.sucursal_codigo)[0].nombre.toUpperCase()}` : `${this.locales.filter(element => element.id == this.filtro.local_codigo)[0].nombre.toUpperCase()}`}`]
        // asigno el contenido del centro de la dona
        dona.options.elements.center.text = `${isNaN(chartData.alcanzado) ? '0' : chartData.alcanzado.toString().replace('.', ',')}%`
        if (this.$vuetify.theme.dark) dona.options.elements.center.color = '#FFFFFF'
        else dona.options.elements.center.color = '#000000'
        // acomodo la legenda
        dona.options.legend.display = true
        dona.options.legend.labels.padding = 10
        dona.padre = 1
        return dona
      }else{
        // armo un listado de donas
        let listado = []
        // pregunto si armo un listado de sucursales o locales
        if (chartData.pais == 1){
          // armo un listado de sucursales
          for (let id in this.sucursales){
            let objSuc = this.objLocales.filter(element => element.sucursal_codigo == this.sucursales[id].id).reduce((sum, item) => sum + roundNumber(item.cantidad, 2), 0)
            // 10-10-2024 por MMURILLO, separo accesorios de total de facturacion
            let objAlcSuc = 0 
            if (this.filtro.tipo_objetivo == 5) objAlcSuc = this.objVendedores.filter(element => element.sucursal_cod == this.sucursales[id].id).reduce((sum, item) => sum + roundNumber(item.objetivo_total_alcanzado, 2), 0)
            else objAlcSuc = this.objVendedores.filter(element => element.sucursal_cod == this.sucursales[id].id).reduce((sum, item) => sum + roundNumber(item.obj_acc_alcanzado, 2), 0)
            if (objSuc > 0){
              let porcObjAlc = roundNumber((objAlcSuc * 100) / objSuc, 2)
              let porcPend = 0
              if (porcObjAlc < 100) porcPend = roundNumber(100 - porcObjAlc, 2)
              // armo mi dona
              let dona = this.setNewDona(`sucHija${id}`)
              dona.data.datasets = [{
                data: porcPend == 0 ? [porcObjAlc] : [porcObjAlc, porcPend],
                backgroundColor: porcPend == 0 ? ['#00FF00'] : ['#00FF00', '#999999'],
                borderColor: porcPend == 0 ? ['#000000'] : ['#000000', '#000000']
              }]
              dona.data.labels = [`${this.sucursales[id].nombre}`]
              dona.options.legend.display = false
              dona.options.tooltips.enabled = false
              // asigno el contenido del centro de la dona
              dona.options.elements.center.text = `${parseInt(porcObjAlc)}%`
              if (this.$vuetify.theme.dark) dona.options.elements.center.color = '#FFFFFF'
              else dona.options.elements.center.color = '#000000'
              dona.options.elements.center.maxFontSize = (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs) ? 15 : 18
              // asigno el objetivo logrado
              dona.logrado = porcObjAlc
              listado.push(dona)
            }
          }
        }else if (chartData.sucursal == 1 && chartData.local == 0){
          // armo un listado de locales
          for (let id in this.locales){
            let objLoc = this.objLocales.filter(element => element.pto_vta_codigo == this.locales[id].id).reduce((sum, item) => sum + roundNumber(item.cantidad, 2), 0)
            // 10-10-2024 por MMURILLO, separo accesorios de total de facturacion
            let objAlcLoc = 0
            if (this.filtro.tipo_objetivo == 5) objAlcLoc = this.objVendedores.filter(element => element.punto_venta_cod == this.locales[id].id).reduce((sum, item) => sum + roundNumber(item.objetivo_total_alcanzado, 2), 0)
            else objAlcLoc = this.objVendedores.filter(element => element.punto_venta_cod == this.locales[id].id).reduce((sum, item) => sum + roundNumber(item.obj_acc_alcanzado, 2), 0)
            if (objLoc > 0){
              let porcObjAlc = roundNumber((objAlcLoc * 100) / objLoc, 2)
              let porcPend = 0
              if (porcObjAlc < 100) porcPend = roundNumber(100 - porcObjAlc, 2)
              // armo mi dona
              let dona = this.setNewDona(`locHijo${id}`)
              dona.data.datasets = [{
                data: porcPend == 0 ? [porcObjAlc] : [porcObjAlc, porcPend],
                backgroundColor: porcPend == 0 ? ['#00FF00'] : ['#00FF00', '#999999'],
                borderColor: porcPend == 0 ? ['#000000'] : ['#000000', '#000000']
              }]
              dona.data.labels = [`${this.locales[id].nombre.indexOf('-') == -1 ? this.locales[id].nombre.toUpperCase() : this.locales[id].nombre.substring(this.locales[id].nombre.indexOf('-') + 2, this.locales[id].nombre.length).toUpperCase()}`]
              dona.options.legend.display = false
              dona.options.tooltips.enabled = false
              // asigno el contenido del centro de la dona
              dona.options.elements.center.text = `${parseInt(porcObjAlc)}%`
              if (this.$vuetify.theme.dark) dona.options.elements.center.color = '#FFFFFF'
              else dona.options.elements.center.color = '#000000'
              dona.options.elements.center.maxFontSize = (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs) ? 15 : 18
              // asigno el objetivo logrado
              dona.logrado = porcObjAlc
              listado.push(dona)
            }
          }
        }
        // ordeno el listado por objetivo logrado
        if (listado.length > 0) order_list_by(listado, 'logrado', 'desc')
        return listado
      }
    },
    executeBarChart(chartData){
      let bar = this.setNewBar(`${chartData.pais == 1 ? `paisBar${chartData.indice}` : (chartData.sucursal == 1 && chartData.local == 0) ? `sucursalBar${chartData.indice}` : `localBar${chartData.indice}`}`)
      let detalles = chartData.pais == 1 ? this.objVendedores : (chartData.sucursal == 1 && chartData.local == 0) ? this.objVendedores.filter(element => element.sucursal_cod == this.filtro.sucursal_codigo) : this.objVendedores.filter(element => element.punto_venta_cod == this.filtro.local_codigo)
      // ordeno el array de vendedores
      order_list_by(detalles, 'porcentaje_total_cumplido', 'desc')
      let dataSets = []
      let labels = []
      for (let id = 0; /*dataSets.length < chartData.cant_elementos && */dataSets.length <= parseInt(this.cantVendedores) && id < detalles.length; id++){
        let existe = dataSets.filter(element => element.vendedor_codigo == detalles[id].vendedor_codigo)
        if (existe.length == 0){
          dataSets.push({
            data: [{x: roundNumber(detalles[id].porcentaje_total_cumplido, 2), y: `${detalles[id].vendedor_codigo} - ${detalles[id].vendedor}`}],
            backgroundColor: ['#6495ED'],
            borderColor: this.$vuetify.theme.dark ? ['#FFFFFF'] : ['#000000'],
            borderWidth: 1,
            label: [`${detalles[id].vendedor}: ${roundNumber(detalles[id].porcentaje_total_cumplido, 2)}%`],
            barPercentage: parseInt(this.cantVendedores) <= 10 ? 7 : cadenaVacia(this.filtro.sucursal_codigo) ? 70 : 15,
            categoryPercentage: 0.6,
            vendedor_codigo: detalles[id].vendedor_codigo
          })
          labels.push(`${detalles[id].vendedor_codigo} - ${detalles[id].vendedor}`)
        }
      }
      if (dataSets.length <= 10) dataSets = dataSets.map(element => ({
        data: element.data,
        backgroundColor: element.backgroundColor,
        borderColor: element.borderColor,
        borderWidth: element.borderWidth,
        label: element.label,
        barPercentage: 7,
        categoryPercentage: 0.3,
        vendedor_codigo: element.vendedor_codigo
      }))
      // vuelvo a generar el grafico
      bar.data.datasets = dataSets
      bar.data.labels = labels
      return bar
    },
    setNewDona(indice){
      return {
        id: indice,
        ref: indice,
        data: {
          datasets: [],
          labels: []
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          aspectRatio: 1.5, // controla el tamaño del gráfico
          cutoutPercentage: 88, // qué tan ajustada será el área de las circunferencias
          title: {},
          tooltips: {
            enabled: true, 
            mode: 'point',
            axis: 'xy',
            borderWidth: 10,
            callbacks: {
              title: function(){
                return ''
              },
              label: function (tooltipItems, datasets) {
                return `${cadenaVacia(datasets.labels[tooltipItems.index]) ? `Pendiente ${datasets.datasets[0].data[tooltipItems.index]}` : datasets.labels[tooltipItems.index]}`
              }
            }
          },
          legend: {
            display: false,
            labels: {
              boxWidth: 0,
              fontStyle: 'bold',
              fontSize: 15,
              fontFamily: 'Arial',
              padding: 5
            }
          },
          legendCallback: chart => {
            return `<p class="text-center" style="word-break: normal; font-size: 10px; line-height: 1; padding-top: 4px;">${chart.data.labels[0]}</p>`
          },
          elements: {
            center: {
              text: ``,
              color: '#000000', // Default is #000000
              fontStyle: 'Arial', // Default is Arial
              sidePadding: 20, // Default is 20 (as a percentage)
              maxFontSize: 22, // Default is 20 (in px), set to false and text will not wrap.
              lineHeight: 25 // Default is 25 (in px), used for when text wraps
            }
          }
        },
        logrado: 0,
        padre: 0
      }
    },
    setNewBar(indice){
      return {
        id: indice,
        ref: indice,
        data: {
          datasets: [],
          labels: []
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          // aspectRatio: 0.7, // controla el tamaño del gráfico
          title: {},
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: true,
                labelString: 'Vendedor'
              }
            }],
            xAxes: [{
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: true
              },
              scaleLabel: {
                display: true,
                labelString: '% Obj Cumplido'
              }
            }]
          },
          legend: {
            display: false
          },
          tooltips: {
            mode: 'point',
            axis: 'xy',
            callbacks: {
              title: function(){
                return ''
              },
              label: function (tooltipItems, datasets) {
                return `${tooltipItems.value}%`
              }
            }
          }
        },
        logrado: 0,
        tipo: 'horizontalBar'
      }
    },
    async generateChartData(pais, sucursal, local, indice){
      return await new Promise(async (resolve, reject) => {
        try{
          let objChart = {
            dona: {},
            donas_hijas: [],
            barra: {}
          }
          let objSolic = this.objLocales.reduce((sum, item) => sum + roundNumber(item.cantidad, 2), 0)
          let objAlc = 0
          if (this.filtro.tipo_objetivo == 5) objAlc = this.objVendedores.reduce((sum, item) => sum + roundNumber(item.objetivo_total_alcanzado, 2), 0)
          else objAlc = this.objVendedores.reduce((sum, item) => sum + roundNumber(item.obj_acc_alcanzado, 2), 0)
          let porcObjAlc = roundNumber((objAlc * 100) / objSolic, 2)
          let porcObjFalta = 0
          if (porcObjAlc < 100) porcObjFalta = roundNumber(100 - porcObjAlc, 2)
          if (pais == 1){
            // armo la dona con el objetivo a nivel pais
            objChart.dona = this.executeDoughnutChart({
              indice: indice, 
              alcanzado: porcObjAlc, 
              faltante: porcObjFalta,
              pais: 1,
              sucursal: 0,
              local: 0,
              opcion: 1
            })
            objChart.donas_hijas = this.executeDoughnutChart({
              indice: indice, 
              alcanzado: 0, 
              faltante: 0,
              pais: 1,
              sucursal: 0,
              local: 0,
              opcion: 2
            })
            objChart.barra = this.executeBarChart({
              indice: indice,
              pais: 1,
              sucursal: 0,
              local: 0,
              cant_elementos: 10
            })
          }else{
            objChart.dona = this.executeDoughnutChart({
              indice: indice, 
              alcanzado: porcObjAlc, 
              faltante: porcObjFalta,
              pais: 0,
              sucursal: sucursal,
              local: local,
              opcion: 1
            })
            objChart.donas_hijas = this.executeDoughnutChart({
              indice: indice, 
              alcanzado: 0, 
              faltante: 0,
              pais: 0,
              sucursal: sucursal,
              local: local,
              opcion: 2
            })
            objChart.barra = this.executeBarChart({
              indice: indice,
              pais: 0,
              sucursal: sucursal,
              local: local,
              cant_elementos: 10
            })
          }
          // respuesta
          return resolve(objChart)
        }catch(error){
          return resolve({})
        }
      })
    },
    async generarGraficos(){
      // objetivos cumplidos por los vendedores
      // 10-10-2024 por MMURILLO, cambiamos los calculos para que dependa del tipo de objetivo
      for (let id in this.objVendedores){
        let porcEq = 0
        if (this.filtro.tipo_objetivo == 5) porcEq = roundNumber((roundNumber(this.objVendedores[id].objetivo_total_alcanzado, 2) * 100) / roundNumber(this.objVendedores[id].obj_solicitado_total_vta, 2), 2)
        else porcEq = roundNumber((roundNumber(this.objVendedores[id].obj_acc_alcanzado, 2) * 100) / roundNumber(this.objVendedores[id].obj_solicitado_acc_piso, 2), 2)
        this.objVendedores[id].porcentaje_total_cumplido = porcEq
      }
      // voy a usar el array graficos que va a contener todos los graficos que quiero mostrar en el template
      this.graficos = []
      // me fijo si estan consultando los resultados de todo el pais
      if (this.permisoPais == 1 && cadenaVacia(this.filtro.sucursal_codigo) && cadenaVacia(this.filtro.local_codigo)){
        // armo mi primer elemento
        let objPais = await this.generateChartData(1, 0, 0, 0)
        this.graficos.push(objPais)
      }else{
        // ejecuto según los filtros que eligieron
        let objFiltro = await this.generateChartData(0, cadenaVacia(this.filtro.sucursal_codigo) ? 0 : 1, cadenaVacia(this.filtro.local_codigo) ? 0 : 1, 0)
        this.graficos.push(objFiltro)
      }
      await new Promise(resolve => setTimeout(resolve, 1))
      // ejecuto cada uno de los graficos
      for (let id in this.graficos){
        // EJECUTO LAS DONAS
        this.$refs[`${this.graficos[id].dona.ref}`][0].executeChart()
        for (let jd in this.graficos[id].donas_hijas){
          this.$refs[`${this.graficos[id].donas_hijas[jd].ref}`][0].executeChart()
        }
        // EJECUTO LAS BARRAS
        this.$refs[`${this.graficos[id].barra.ref}`][0].executeChart()
      }
    },
    resetLine(){
      this.lineaProy.data.datasets = []
      this.lineaProy.data.labels = []
    },
    async generarProyeccion(){
      if (this.ventas.length > 0){
        var mayor = 0
        // son muchos registros, paso a armar el array para el grafico y recién ordeno por fecha, tengo cuidado de no ciclar todo el array de ventas
        this.proyecciones = []
        let unElem = this.ventas[0]
        while (this.ventas.length > 0 && unElem != null){
          // let ventasUnDia = this.ventas.filter(element => moment(element.fecha.toString().substring(0, 10), 'YYYY-MM-DD').format('DD/MM/YYYY') == moment(unElem.fecha.toString().substring(0, 10), 'YYYY-MM-DD').format('DD/MM/YYYY'))
          let ventasUnDia = this.ventas.filter(element => element.fecha == unElem.fecha)
          // formo el objeto que necesito
          let unObj = {
            fecha: momentTz.tz(unElem.fecha + ' 12:00:00', 'DD/MM/YYYY HH:mm:ss', 'UTC').format(),
            fecha_real: unElem.fecha,
            total_facturado: roundNumber(parseInt(ventasUnDia.reduce((sum, item) => sum + roundNumber(item.importe, 2), 0) - ventasUnDia.reduce((sum, item) => sum + roundNumber(item.descuento, 2), 0)) / 1000000, 3),
            acumulado_facturacion: 0,
            proyectado_facturacion: 0,
            dia_habil: 0,
            original: 1,
            indice: -1
          }
          // lo guardo en mi array de proyecciones
          this.proyecciones.push(unObj)
          // paso a quitar de ventas las que tengo en ventasUnDia
          for (let id in ventasUnDia){
            let pos = this.ventas.indexOf(ventasUnDia[id])
            this.ventas.splice(pos, 1)
          }
          // reasigno unElem
          if (this.ventas.length > 0) unElem = this.ventas[0]
          else unElem = null
        }
        // ordeno ascendente por fecha
        order_list_by_date2(this.proyecciones, 'fecha', 'desc')
        // controlo si el listado llega hasta fin de mes
        let ultimoDia = moment().endOf('month').format('DD/MM/YYYY')
        let existe = this.proyecciones.filter(element => element.fecha_real == ultimoDia)
        if (existe.length == 0){
          this.proyecciones[this.proyecciones.length - 1].original = 2
          // completo los dias que faltan para que acabe el mes
          let fecha_desde = moment(this.proyecciones[this.proyecciones.length - 1].fecha_real, 'DD/MM/YYYY').add(1, 'days').toDate()
          let fecha_hasta = moment(ultimoDia, 'DD/MM/YYYY').toDate()
          // calculo el total facturado de acuerdo a los ultimos 6 dias
          let totalFactPromedio = 0
          if (this.proyecciones.length >= 7) totalFactPromedio = roundNumber(this.proyecciones.filter(element => moment(element.fecha_real, 'DD/MM/YYYY').toDate() >= moment(this.proyecciones[this.proyecciones.length - 1].fecha_real, 'DD/MM/YYYY').add(-7, 'days').toDate() && moment(element.fecha_real, 'DD/MM/YYYY').toDate() < moment(this.proyecciones[this.proyecciones.length - 1].fecha_real, 'DD/MM/YYYY').toDate()).reduce((sum, item) => sum + roundNumber(item.total_facturado, 2), 0) / 6, 3)
          else totalFactPromedio = roundNumber(this.proyecciones.reduce((sum, item) => sum + roundNumber(item.total_facturado, 2), 0) / this.proyecciones.length, 3)
          while (fecha_desde <= fecha_hasta){
            let unObj = {
              fecha: momentTz.tz(moment(fecha_desde).format('DD/MM/YYYY').toString() + ' 12:00:00', 'DD/MM/YYYY HH:mm:ss', 'UTC').format(),
              fecha_real: moment(fecha_desde).format('DD/MM/YYYY').toString(),
              total_facturado: totalFactPromedio,
              acumulado_facturacion: 0,
              proyectado_facturacion: 0,
              dia_habil: 0,
              original: 0,
              indice: -1
            }
            this.proyecciones.push(unObj)
            fecha_desde = moment(fecha_desde).add(1, 'days').toDate()
          }
        }
        // asigno el día habil
        let diaHabil = 1
        for (let id in this.proyecciones){
          this.proyecciones[id].indice = id
          this.proyecciones[id].dia_habil = diaHabil
          // obtengo la facturación acumulada
          this.proyecciones[id].acumulado_facturacion = parseInt(id) == 0 ? roundNumber(this.proyecciones[parseInt(id)].total_facturado, 0) : roundNumber(this.proyecciones[parseInt(id)].total_facturado, 0) + roundNumber(this.proyecciones[parseInt(id) - 1].acumulado_facturacion, 0)
          // calculo la facturacion proyectada
          this.proyecciones[id].proyectado_facturacion = roundNumber(((parseInt(id) == 0 ? this.proyecciones[id].total_facturado : /*this.proyecciones.filter(element => element.indice != -1).reduce((sum, item) => sum + roundNumber(item.total_facturado, 2), 0)*/this.proyecciones[id].total_facturado + this.proyecciones[parseInt(id) - 1].acumulado_facturacion) / this.proyecciones[id].dia_habil) * this.diasHabiles, 3)
          if (mayor < parseInt(this.proyecciones[id].proyectado_facturacion)) mayor = parseInt(this.proyecciones[id].proyectado_facturacion)
          // aumento el día habil?
          if (parseInt(id) < this.proyecciones.length - 1 && moment(this.proyecciones[parseInt(id) + 1].fecha.toString().substring(0, 10), 'YYYY-MM-DD').format('dddd').toLowerCase().indexOf('domingo') == -1) diaHabil = diaHabil + 1
        }
      }
      // ya tengo this.proyecciones armado, paso a rearmar la linea
      this.resetLine()
      // armo el dataset y los labels
      let unDataSet = {
        data: this.proyecciones.filter(element => element.original == 1 || element.original == 2).map(item => {return item.proyectado_facturacion}),
        backgroundColor: "transparent",
        borderColor: this.$vuetify.theme.dark ? '#42A5F5' : '#2196F3',
        pointBackgroundColor: this.$vuetify.theme.dark ? '#42A5F5' : '#2196F3',
        yAxisID: 'y-axis-1'
      }
      this.lineaProy.data.datasets.push(unDataSet)
      let otroDataSet = {
        data: this.proyecciones.map(item => {return {x: moment(item.fecha).format('DD-MMM'), y: (item.original == 0 || item.original == 2) ? item.proyectado_facturacion : null}}),
        backgroundColor: "transparent",
        borderColor: this.$vuetify.theme.dark ? '#42A5F5' : '#2196F3',
        pointBackgroundColor: this.$vuetify.theme.dark ? '#42A5F5' : '#2196F3',
        borderDash: [10, 5],
        yAxisID: 'y-axis-1'
      }
      if (otroDataSet.data.length > 0) this.lineaProy.data.datasets.push(otroDataSet)
      this.lineaProy.data.labels = this.proyecciones.map(item => {return moment(item.fecha).format('DD-MMM')})
      // con el mayor, lo asigno en el maximo de los ejes y
      this.lineaProy.options.scales.yAxes[0].ticks.max = mayor + (10*mayor.toString().length)
      this.lineaProy.options.scales.yAxes[1].ticks.max = mayor + (10*mayor.toString().length)
      await new Promise(resolve => setTimeout(resolve, 1))
      this.$refs.line1.executeChart()
    }
  },
  watch: {
    '$vuetify.theme.dark':{
      handler: function(val){
        if (val) Chart.defaults.global.defaultFontColor = 'white'
        else Chart.defaults.global.defaultFontColor = 'black'
        this.generarGraficos()
        this.generarProyeccion()
      },
      deep: true
    },
    '$vuetify.breakpoint.width':{
      handler: function(val){
        this.generarGraficos()
        this.generarProyeccion()
      },
      deep: true
    }
  },
}
</script>

<style>

</style>