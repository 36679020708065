<template>
    <div class="chart-container">
      <div :style="data.labels.length <= 10 ? `height: ${data.labels.length * 35}px;` : `height: ${data.labels.length * 25}px;`">
        <canvas :id="id"></canvas>
      </div>
    </div>
</template>

<script>
import { Chart } from 'chart.js'
export default {
  props: {
    datos: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  computed: {
    id: {
      get(){
        return this.datos.id
      }
    },
    data: {
      get(){
        return this.datos.data
      }
    },
    options: {
      get(){
        return this.datos.options
      }
    },
    tipo: {
      get(){
        return this.datos.tipo
      }
    }
  },
  mounted () {

  },
  data() {
    return {
      barChart: null
    }
  },
  methods: {
    executeChart(){
      this.clearChart()
      const canvas = document.getElementById(this.id)
      const ctx = canvas.getContext('2d')
      this.barChart = new Chart(ctx, {
        type: this.tipo,
        data: this.data,
        options: this.options
      })
    },
    clearChart(){
      if (this.barChart != null) this.barChart.destroy()
    }
  },
  watch: {

  }
}
</script>

<style scoped>
  .chart-container {
    width: 100%;
    max-height: 350px;  /*Altura fija para permitir el desplazamiento */
    overflow-y: scroll;
  }
</style>